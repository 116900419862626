import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, LoginGuard } from "./auth.guard";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MaintenancesPageComponent } from "./pages/maintenances-page/maintenances-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginPageComponent, canActivate: [LoginGuard] },
  { path: "maintenances", component: MaintenancesPageComponent, canActivate: [AuthGuard] },
  { path: "error", component: ErrorPageComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
