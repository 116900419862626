import { inject } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { SessionService } from "./sharedServices/session.service";
export const AuthGuard = (): UrlTree | boolean => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  if (sessionService.token) {
    return true;
  }
  // Redirect to the login page
  return router.parseUrl("/login");
};

export const LoginGuard = (): UrlTree | boolean => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  if (!sessionService.token) {
    return true;
  }
  // Redirect to the login page
  return router.parseUrl("/maintenances");
};
