import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, ErrorHandler, OnInit, ViewChild } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IUser } from "./data/users/user.interface";
import { AppError } from "./errorsHandlers/app-error.interface";
import { GlobalErrorHandler } from "./errorsHandlers/global-error-handler";
import { FileLogger } from "./helpers/fileLogger";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { SessionService } from "./sharedServices/session.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("drawer") drawer: MatSidenav;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );
  public ENV = environment;
  public globalError: AppError;
  public loading: boolean;
  public isExpanded = true;
  public showSubmenu = false;
  public isShowing = false;
  public pageTitle = "";
  public user: IUser;
  public isAuthorizedSelectOrg = false;
  private loadingLoginPage$: Subscription = null;

  constructor(
    private globalErrorHandler: ErrorHandler,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<unknown>,
    public matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.registerFontClassAlias("solid", "fas");
  }
  ngOnInit(): void {
    // Listen global error
    (this.globalErrorHandler as GlobalErrorHandler).catchedError.subscribe((error: AppError) => {
      // also console error to get source only in not production env
      if (!environment.production) {
        FileLogger.error(this, "globalErrorHandler in ngOnInit", error, "none");
      }
      this.globalError = error;
      this.snackBar.open(error.message, "ok", {
        duration: 10000,
      });
    });

    // https://angular.io/api/router/RouterEvent
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
      // https://stackoverflow.com/questions/45450739/angular4-x-navigationend-url-vs-navigationend-urlafterredirects
      this.setupPageTitle(ev.urlAfterRedirects ? ev.urlAfterRedirects : ev.url);
    });

    this.dateAdapter.setLocale(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(() => {
      this.dateAdapter.setLocale(this.translateService.currentLang);
    });

    this.sessionService.watchUser().subscribe((user) => {
      this.user = user;
    });
  }

  /**
   * Method to link child components with appComponent
   * @param componentReference child Component
   */
  public onActivate(componentReference: unknown): void {
    if (componentReference instanceof LoginPageComponent) {
      // management of the appComponent loader display according to the Login page
      if (this.loadingLoginPage$) {
        this.loadingLoginPage$.unsubscribe();
      }
      this.loadingLoginPage$ = componentReference.loadingPage.subscribe((loading) => {
        this.loading = loading;
      });
    }
  }

  public logout(): void {
    this.drawer.close();
    setTimeout(() => {
      this.sessionService.clear();
      this.router.navigate(["/"]);
    }, 0); // we need this timeOut to be sure that every javascript changes are done before disconnecting
  }

  private setupPageTitle(url: string) {
    let name = url.split(";")[0].replace("/", "");
    name = name?.split("?")[0];
    if ((!url.includes("%3B") && !url.includes("%3D") && name) || url.includes("patient;id")) {
      this.pageTitle = "page." + name + ".title";
    } else if (url.includes("%3B") && url.includes("%3D")) {
      this.router.navigateByUrl(url.replace("%3B", ";").replace("%3D", "="));
    } else if (url.includes("%3B")) {
      this.router.navigateByUrl(url.replace("%3B", ";"));
    } else if (url.includes("%3D")) {
      this.router.navigateByUrl(url.replace("%3D", "="));
    } else {
      this.router.navigate(["/login"]);
    }
  }

  // Start of sub-menu control toggle
  public mouseEnter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  public mouseLeave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
}
