import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { LayoutModule } from "@angular/cdk/layout";
import { DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GlobalErrorHandler } from "./errorsHandlers/global-error-handler";
import { HttpErrorInterceptor } from "./errorsHandlers/http-error-interceptor";
import { FileLogger } from "./helpers/fileLogger";
import { I18nModule } from "./i18n/i18n.module";
import { MatPaginatorI18N } from "./i18n/mat-paginator-i18n";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { ChangeLangComponent } from "./pages/login-page/change-lang/change-lang.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MaintenanceFormDialogComponent } from "./pages/maintenances-page/maintenance-form-dialog/maintenance-form-dialog.component";
import { MaintenancesPageComponent } from "./pages/maintenances-page/maintenances-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { DisplayEnvironmentDialogComponent } from "./sharedComponents/display-environment-dialog/display-environment-dialog.component";
import { ShowHidePasswordComponent } from "./sharedComponents/show-hide-password/show-hide-password.component";
import { ApiService } from "./sharedServices/api.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    ErrorPageComponent,
    DisplayEnvironmentDialogComponent,
    ChangeLangComponent,
    ShowHidePasswordComponent,
    MaintenancesPageComponent,
    MaintenanceFormDialogComponent,
  ],
  imports: [
    BrowserModule,
    I18nModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    MatChipsModule,
    MatMenuModule,
    MatChipsModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
  ],
  providers: [
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18N },
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    CookieService,
    MatIconRegistry,
    FileLogger,
    DecimalPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
