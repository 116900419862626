import { Component, Inject, OnDestroy } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";
import { IMaintenance, MaintenanceStatus, MaintenanceType } from "src/app/data/maintenances/maintenance.interface";
import { MaintenanceService } from "src/app/data/maintenances/maintenance.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SessionService } from "src/app/sharedServices/session.service";

@Component({
  selector: "app-maintenance-form-dialog",
  templateUrl: "./maintenance-form-dialog.component.html",
  styleUrls: ["./maintenance-form-dialog.component.scss"],
})
export class MaintenanceFormDialogComponent implements OnDestroy {
  public maintenanceForm = this.fb.group({
    plannedStart: [new Date(), [Validators.required]],
    estimatedEnd: [new Date()],
    message: [""],
    maintenanceType: [MaintenanceType.CLASSIC, [Validators.required]],
    maintenanceStatus: [MaintenanceStatus.PLANNED, [Validators.required]],
  });
  public maintenanceStatus = MaintenanceStatus;
  public maintenanceTypes = MaintenanceType;
  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<MaintenanceFormDialogComponent>,
    public sessionService: SessionService,
    private translateService: TranslateService,
    private maintenanceService: MaintenanceService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      maintenance: IMaintenance;
      isCreation: boolean;
    }
  ) {
    if (this.data.isCreation) {
      this.maintenanceForm.get("maintenanceStatus").disable();
    } else {
      this.setupFormForUpdate();
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupFormForUpdate(): void {
    if (!this.data?.maintenance) {
      return;
    }
    this.maintenanceForm.get("plannedStart").setValue(new Date(this.data.maintenance.plannedStart));
    if (this.data.maintenance.estimatedEnd) {
      this.maintenanceForm.get("estimatedEnd").setValue(new Date(this.data.maintenance.estimatedEnd));
    }
    this.maintenanceForm.get("maintenanceType").setValue(this.data.maintenance.maintenanceType);
    this.maintenanceForm.get("maintenanceStatus").setValue(this.data.maintenance.maintenanceStatus);
    this.maintenanceForm.get("message").setValue(this.data.maintenance.message);
  }

  public save(): void {
    try {
      if (this.maintenanceForm.valid) {
        const plannedStart = this.maintenanceForm.get("plannedStart").value?.toISOString();
        const estimatedEnd = this.maintenanceForm.get("estimatedEnd").value?.toISOString();
        const type = this.maintenanceForm.get("maintenanceType").value;
        const status = this.maintenanceForm.get("maintenanceStatus").value;
        const msg = this.maintenanceForm.get("message").value;
        if (this.data.isCreation) {
          this.maintenanceService
            .post(plannedStart, estimatedEnd, type, msg)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.close();
            });
        } else {
          this.maintenanceService
            .put(this.data.maintenance._id.toString(), status, plannedStart, estimatedEnd, type, msg)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.close();
            });
        }
      } else {
        this.snackBar.open("Not valid", undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      }
    } catch (err) {
      FileLogger.error(this, "save() failed", err, "none");
    }
  }
  private close(): void {
    this.dialogRef.close(true);
    this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  private formatDate(val: Date): Date | null {
    const d = val ? new Date(val) : null;
    const d2 = d ? Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) : null;
    return d2 ? new Date(d2) : null;
  }
}
