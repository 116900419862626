import { EventEmitter, Injectable } from "@angular/core";
import { Observable, last, map } from "rxjs";
import { IUser } from "src/app/data/users/user.interface";
import { ApiService } from "src/app/sharedServices/api.service";
import { SessionService } from "src/app/sharedServices/session.service";

interface IAuthentification {
  token: string;
  account: IUser;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private api: ApiService, private sessionService: SessionService) {}

  public authenticate(email: string, password: string, event: EventEmitter<boolean>): Observable<IUser> {
    const obs = this.api.post(
      "authenticate",
      {
        login: email,
        password,
      },
      null,
      false,
      true
    ) as Observable<IAuthentification>;
    return obs.pipe(
      last(),
      map((auth: IAuthentification) => {
        event?.emit(true);
        this.sessionService.token = auth.token;
        this.sessionService.user = auth.account;
        return auth.account;
      })
    );
  }
}
