import * as ESAPI from "node-esapi";

export class FileLogger {
  public static log(
    source: NonNullable<unknown>,
    message: string,
    encoderType: "html" | "javascript" | "url" | "none" = "javascript"
  ): void {
    try {
      if (!encoderType || encoderType === "html") {
        message = ESAPI.encoder().encodeForHTML(message);
      } else if (encoderType === "javascript") {
        message = ESAPI.encoder().encodeForJavaScript(message);
      } else if (encoderType === "url") {
        message = ESAPI.encoder().encodeForURL(message);
      }
      const s = typeof source === "string" ? source : typeof source === "function" ? source.name : source.constructor.name;
      console.log(s, message);
    } catch (error) {
      console.error(error);
    }
  }

  public static warn(
    source: NonNullable<unknown>,
    message: string,
    encoderType: "html" | "javascript" | "url" | "none" = "javascript"
  ): void {
    try {
      if (!encoderType || encoderType === "html") {
        message = ESAPI.encoder().encodeForHTML(message);
      } else if (encoderType === "javascript") {
        message = ESAPI.encoder().encodeForJavaScript(message);
      } else if (encoderType === "url") {
        message = ESAPI.encoder().encodeForURL(message);
      }
      const s = typeof source === "string" ? source : typeof source === "function" ? source.name : source.constructor.name;
      console.info(s, message);
    } catch (error) {
      console.error(error);
    }
  }

  public static info(
    source: NonNullable<unknown>,
    message: string,
    encoderType: "html" | "javascript" | "url" | "none" = "javascript"
  ): void {
    try {
      if (!encoderType || encoderType === "html") {
        message = ESAPI.encoder().encodeForHTML(message);
      } else if (encoderType === "javascript") {
        message = ESAPI.encoder().encodeForJavaScript(message);
      } else if (encoderType === "url") {
        message = ESAPI.encoder().encodeForURL(message);
      }
      const s = typeof source === "string" ? source : typeof source === "function" ? source.name : source.constructor.name;
      console.warn(s, message);
    } catch (error) {
      console.error(error);
    }
  }

  public static error(
    source: NonNullable<unknown>,
    message: string,
    err: unknown,
    encoderType: "html" | "javascript" | "url" | "none" = "javascript"
  ): void {
    try {
      if (!encoderType || encoderType === "html") {
        message = ESAPI.encoder().encodeForHTML(message);
      } else if (encoderType === "javascript") {
        message = ESAPI.encoder().encodeForJavaScript(message);
      } else if (encoderType === "url") {
        message = ESAPI.encoder().encodeForURL(message);
      }
      const s = typeof source === "string" ? source : typeof source === "function" ? source.name : source.constructor.name;
      const e = err ? (err instanceof Error ? err : JSON.stringify(err)) : "";
      console.error(s, message, e);
      console.error(err && err instanceof Error ? err.stack : "");
    } catch (error) {
      console.error(error);
    }
  }
}
