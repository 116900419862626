<form [formGroup]="maintenanceForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title>
    {{ "page.maintenances.title" | translate }}
  </h2>

  <mat-dialog-content>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        formControlName="message"
        autocomplete="off"
        placeholder="{{ 'maintenance.message' | translate }}"
      />
    </mat-form-field>
    <br />
    <mat-form-field class="font-12">
      <mat-label>{{ "maintenance.type.title" | translate }}</mat-label>
      <mat-select formControlName="maintenanceType">
        <mat-option *ngFor="let type of maintenanceTypes | keyvalue" [value]="type.value">
          {{ "maintenance.type." + type.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="font-12">
      <mat-label>{{ "maintenance.status.title" | translate }}</mat-label>
      <mat-select formControlName="maintenanceStatus">
        <mat-option *ngFor="let status of maintenanceStatus | keyvalue" [value]="status.value">
          {{ "maintenance.status." + status.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />
    <div class="datePickerContainer ml-5">
      <mat-form-field class="calendarInGrid font-12">
        <mat-label>{{ "maintenance.plannedStart" | translate }}</mat-label>
        <input matInput [ngxMatDatetimePicker]="plannedStartDate" formControlName="plannedStart" />
        <mat-datepicker-toggle matSuffix [for]="plannedStartDate"> </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #plannedStartDate
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="5"
          color="primary"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="calendarInGrid font-12">
        <mat-label>{{ "maintenance.estimatedEnd" | translate }}</mat-label>
        <input matInput [ngxMatDatetimePicker]="estimatedEndDate" formControlName="estimatedEnd" />
        <mat-datepicker-toggle matSuffix [for]="estimatedEndDate"> </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #estimatedEndDate
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="5"
          color="primary"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="save()" style="color: white !important; font-weight: 500; font-size: 14px">
      {{ "btn.save" | translate }}
    </button>
  </mat-dialog-actions>
</form>
