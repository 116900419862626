import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";
import { IMaintenance, MaintenanceStatus, MaintenanceType } from "./maintenance.interface";
import { MaintenanceService } from "./maintenance.service";

export class MaintenanceDataSource extends DataSource<IMaintenance> {
  // Behavior subject that contains the last value of the data
  private dataSubject = new BehaviorSubject<IMaintenance[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(private service: MaintenanceService) {
    super();
  }
  /**
   * This method will be called once by the Data Table at table bootstrap time.
   * The Data Table expects this method to return an Observable, and the values
   * of that observable contain the data that the Data Table needs to display.
   * It is use automatically by mat-table via dataSource
   * @param collectionViewer provides an Observable that emits information about what data is being
   * displayed (the start index and the end index)
   * @returns data that the Data Table needs to display
   */
  connect(_collectionViewer: CollectionViewer): Observable<IMaintenance[]> {
    return this.dataSubject.asObservable();
  }

  /**
   * This method is called once by the data table at component destruction time.
   * In this method, we are going to complete any observables that we have created
   * internally in this class, in order to avoid memory leaks.
   * We are going to complete both the dataSubject and the loadingSubject,
   * which are then going to trigger the completion of any derived observables.
   * @param collectionViewer provides an Observable that emits information about what data is being
   * displayed (the start index and the end index)
   */
  disconnect(_collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public loadData(
    maintenanceStatus?: MaintenanceStatus[],
    maintenanceType?: MaintenanceType[],
    from?: string,
    to?: string,
    limit?: number,
    skip?: number
  ): void {
    this.loadingSubject.next(true);
    this.service
      .get(null, maintenanceStatus, maintenanceType, from, to, limit, skip)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: IMaintenance[]) => {
        this.dataSubject.next(data);
        this.loadingSubject.next(false);
      });
  }
}
