import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Tools } from "src/app/helpers/tools";
import { LanguagesService } from "src/app/i18n/languages.service";
import { SessionService } from "src/app/sharedServices/session.service";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent {
  public isLoggedIn = Tools.isDefined(this.sessionService.user);
  private readonly COOKIE_DEADLINE_EXPIRED = "redirect-deadlineexpired";
  private readonly COOKIE_ACCESS_DENIED = "redirect-accessdenied";
  private readonly COOKIE_NOT_VALID_PARAMS = "redirect-invalidparams";
  private readonly COOKIE_NOT_VALID_REQUEST = "redirect-invalidrequest";
  private readonly COOKIE_EXPIRED_FILE = "redirect-expired-file";

  public message = "";

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private languagesService: LanguagesService
  ) {
    this.translateService.use(this.languagesService.userLangOrBrowser).subscribe(() => {
      if (this.cookieService.check(this.COOKIE_DEADLINE_EXPIRED)) {
        this.message = "api.errors.deadlineexpired";
        this.cookieService.delete(this.COOKIE_DEADLINE_EXPIRED);
      } else if (this.cookieService.check(this.COOKIE_ACCESS_DENIED)) {
        this.message = "api.errors.ACCESS DENIED";
        this.cookieService.delete(this.COOKIE_ACCESS_DENIED);
      } else if (this.cookieService.check(this.COOKIE_NOT_VALID_PARAMS)) {
        this.message = "api.errors.InvalidData";
        this.cookieService.delete(this.COOKIE_NOT_VALID_PARAMS);
      } else if (this.cookieService.check(this.COOKIE_NOT_VALID_REQUEST)) {
        this.message = "api.errors.invalidrequest";
        this.cookieService.delete(this.COOKIE_NOT_VALID_REQUEST);
      } else if (this.cookieService.check(this.COOKIE_EXPIRED_FILE)) {
        this.message = "api.errors.expiredFile";
        this.cookieService.delete(this.COOKIE_EXPIRED_FILE);
      } else {
        this.message = "api.errors.unknowerror";
      }
    });
  }
}
