import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { IMaintenance, MaintenanceStatus } from "src/app/data/maintenances/maintenance.interface";
import { MaintenanceService } from "src/app/data/maintenances/maintenance.service";
import { IUser } from "src/app/data/users/user.interface";
import { AppError, ERROR_MSG } from "src/app/errorsHandlers/app-error.interface";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { environment } from "src/environments/environment";
import packageInfo from "../../../../package.json";
import { FormPage } from "../formPage";
import { AuthenticationService } from "./authentication.service";
import { ChangeLangMode } from "./change-lang/change-lang.component";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent extends FormPage implements AfterViewInit, OnDestroy {
  @Output() loadingPage: EventEmitter<boolean> = new EventEmitter();
  public loading = false;
  public errorMessage: string;
  public langMode: ChangeLangMode;
  public version: string = packageInfo.version;
  public loginForm = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    password: ["", Validators.required],
    error: [],
  });

  public isDev = environment.envName === "dev";
  public isTest = environment.envName === "test";
  public showErrorForm = false;
  public selectedError: number;
  private ongoingMaintenance: IMaintenance;
  public ongoingMaintenanceEnd: Date;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private maintenanceService: MaintenanceService,
    public breakpointObserver: BreakpointObserver
  ) {
    super();
    breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.langMode = value.matches ? ChangeLangMode.DROPDOWN : ChangeLangMode.BUTTON;
      });
  }

  ngAfterViewInit(): void {
    this.maintenanceService
      .get(null, [MaintenanceStatus.ONGOING])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((maintenances: IMaintenance[]) => {
        this.ongoingMaintenance = maintenances?.length ? maintenances[0] : null;
        const threeHoursLater: Date = Tools.dateAdd(new Date(), "hours", 3);
        this.ongoingMaintenanceEnd =
          this.ongoingMaintenance?.estimatedEnd && Tools.compareDates(new Date(), new Date(this.ongoingMaintenance.estimatedEnd)) < 1
            ? new Date(this.ongoingMaintenance.estimatedEnd)
            : threeHoursLater;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public login(): void {
    this.errorMessage = undefined;
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService.authenticate(this.loginForm.get("email").value, this.loginForm.get("password").value, this.loadingPage).subscribe({
        next: (user: IUser) => {
          if (user) {
            this.router.navigate(["/maintenances"]);
            this.loadingPage.emit(false);
          } else {
            FileLogger.error(this, "Error while authenticating, no user", null, "none");
          }
        },
        error: (error: AppError) => {
          this.loading = false;
          this.loadingPage.emit(false);
          if (error.message === ERROR_MSG.NEED_PASSWORD_REFRESH) {
            // TODO password refresh
          } else {
            if (error.global) {
              throw error;
            } else {
              this.errorMessage = error.message;
            }
          }
        },
      });
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }
  public getForm(): FormGroup {
    return this.loginForm;
  }
}
