import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ApiService } from "../sharedServices/api.service";
import { SessionService } from "../sharedServices/session.service";
import { ITranslation } from "./translations.interface";

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  private listLanguages: ITranslation[] = [];

  constructor(private api: ApiService, private translateService: TranslateService, private sessionService: SessionService) {}

  public list(): Observable<ITranslation[]> {
    if (this.listLanguages.length) {
      return of(this.listLanguages);
    }
    const obs = this.api.get("languages", null, true) as Observable<ITranslation[]>;
    return obs.pipe(
      tap((languages) => {
        this.listLanguages = languages;
      })
    );
  }

  /**
   * Return the user language from the localStorage if existing or the browser language. If browser language not supported, return the default language
   */
  public get userLangOrBrowser(): string {
    let lang = localStorage.getItem("lang");

    if (!lang) {
      lang = this.translateService.getBrowserLang();
      if (!this.translateService.getLangs().includes(lang)) {
        lang = this.sessionService.DEFAULT_LANG;
      }
    }
    return lang;
  }
}
