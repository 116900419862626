import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Subject, takeUntil } from "rxjs";
import { MaintenanceDataSource } from "src/app/data/maintenances/maintenance.datasource";
import { IMaintenance, MaintenanceStatus, MaintenanceType } from "src/app/data/maintenances/maintenance.interface";
import { MaintenanceService } from "src/app/data/maintenances/maintenance.service";
import { MaintenanceFormDialogComponent } from "./maintenance-form-dialog/maintenance-form-dialog.component";

@Component({
  selector: "app-maintenances-page",
  templateUrl: "./maintenances-page.component.html",
  styleUrls: ["./maintenances-page.component.scss"],
})
export class MaintenancesPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public limit = 25;
  public count = 0;
  public datasource: MaintenanceDataSource = new MaintenanceDataSource(this.maintenanceService);
  public displayedColumns = [
    "maintenance.status.title",
    "maintenance.plannedStart",
    "maintenance.estimatedEnd",
    "maintenance.type.title",
    "maintenance.message",
    "action",
  ];
  public maintenanceStatus = MaintenanceStatus;
  public maintenanceTypes = MaintenanceType;
  public statusFilter: MaintenanceStatus[] | null = null;
  public typeFilter: MaintenanceType[] | null = null;
  public fromFilter: string | null = null;
  public toFilter: string | null = null;
  public filterDatesForm = this.fb.group({
    from: new FormControl(null, {
      updateOn: "blur",
    }),
    to: new FormControl(null, {
      updateOn: "blur",
    }),
  });
  private onDestroy$ = new Subject<void>();

  constructor(private maintenanceService: MaintenanceService, private fb: FormBuilder, private dialog: MatDialog) {
    this.datasource.loadData(this.statusFilter, this.typeFilter, this.fromFilter, this.toFilter, this.limit);
  }

  ngAfterViewInit(): void {
    this.maintenanceService
      .count(this.statusFilter, this.typeFilter, this.fromFilter, this.toFilter)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((c) => (this.count = c));

    // observe the change in paginator and reload data
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.reloadData();
    });
    this.filterDatesForm
      .get("from")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        const d = val ? new Date(val) : null;
        const d2 = d ? Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) : null;
        const formatDate = d2 ? new Date(d2) : null;
        formatDate?.setUTCHours(0, 0, 0, 0);
        this.fromFilter = formatDate ? formatDate.toISOString() : null;
        this.reloadData();
      });

    this.filterDatesForm
      .get("to")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        const d = val ? new Date(val) : null;
        const d2 = d ? Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) : null;
        const formatDate = d2 ? new Date(d2) : null;
        formatDate?.setUTCHours(23, 59, 59, 999);
        this.toFilter = formatDate ? formatDate.toISOString() : null;
        this.reloadData();
      });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public reloadData(): void {
    this.paginator.pageIndex = 0;
    const skip = this.paginator.pageIndex > 0 ? this.paginator.pageIndex : null;
    this.datasource.loadData(this.statusFilter, this.typeFilter, this.fromFilter, this.toFilter, this.paginator.pageSize, skip);
  }

  public createMaintenance(): void {
    const dialogRef = this.dialog.open(MaintenanceFormDialogComponent, {
      data: { maintenance: null, isCreation: true },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((needReload) => {
        if (needReload) this.reloadData();
      });
  }
  public updateMaintenance(maintenance: IMaintenance): void {
    const dialogRef = this.dialog.open(MaintenanceFormDialogComponent, {
      data: { maintenance, isCreation: false },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((needReload) => {
        if (needReload) this.reloadData();
      });
  }
}
