import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ApiService } from "../sharedServices/api.service";
import { SessionService } from "../sharedServices/session.service";
import { CustomI18nTranslateLoader } from "./customI18nLoader";

registerLocaleData(localeFr);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomI18nTranslateLoader,
        deps: [ApiService],
      },
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "fr-FR" }],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(translate: TranslateService, sessionService: SessionService) {
    // languages list
    translate.addLangs(["fr", "en", "nl", "de", "it"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");

    if (sessionService.userLang) {
      translate.use(sessionService.userLang);
    } else {
      translate.use(translate.getBrowserLang());
    }
  }
}
