<div class="login-main-container">
  <h1 style="color: red; margin: 20px; text-align: center; max-width: 900px">
    {{ "maintenance.infoMessage" | translate }}
    {{ ongoingMaintenanceEnd | date : "HH:mm" }}
  </h1>
  <img class="logo-main-page" src="assets/images/logo_main.png" />
  <mat-card class="login-card">
    <mat-card-content>
      <form [formGroup]="loginForm" autocomplete="off" (keyup.enter)="login()">
        <mat-form-field>
          <mat-label>{{ "page.login.email" | translate }}</mat-label>
          <input matInput formControlName="email" placeholder="user@email.com" />
          <mat-error *ngIf="isFieldValid('email')">{{ "page.login.email-required" | translate }} </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "page.login.password" | translate }}</mat-label>
          <app-show-hide-password>
            <input type="password" formControlName="password" matInput />
          </app-show-hide-password>
          <mat-error *ngIf="isFieldValid('password')">{{ "page.login.password-required" | translate }} </mat-error>
        </mat-form-field>

        <p *ngIf="errorMessage" class="error">
          {{ "api.errors." + errorMessage | translate }}
        </p>

        <app-change-lang [mode]="langMode"></app-change-lang>

        <div class="form-action">
          <button
            [disabled]="loading"
            (click)="login()"
            id="loginBtn"
            mat-raised-button
            color="primary"
            style="color: white !important; font-weight: 500; font-size: 14px"
          >
            {{ "page.login.login" | translate }}
          </button>
        </div>
        <div class="report-problem" *ngIf="!showErrorForm" (click)="showErrorForm = true">
          <p>{{ "page.login.reportIssue" | translate }}</p>
        </div>

        <div class="error-form" *ngIf="showErrorForm">
          <p class="bold">{{ "page.login.errorForm.title" | translate }}</p>
          <mat-radio-group class="radio-group" aria-label="Select an option" formControlName="error">
            <mat-radio-button class="mt-05" [value]="1">
              {{ "page.login.errorForm.option.1" | translate }}
            </mat-radio-button>
            <p *ngIf="loginForm.get('error').value === 1" class="info">
              {{ "page.login.errorForm.info" | translate }}
            </p>
            <mat-radio-button class="mt-05" [value]="2">{{ "page.login.errorForm.option.2" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <p
          class="cgu-text"
          [innerHTML]="'page.login.cguText' | translate : { cgu: ('page.login.cgu' | translate), link: ('page.login.cguLink' | translate) }"
        ></p>
      </form>
    </mat-card-content>
  </mat-card>
  <p class="on-left mt-5">{{ version }}v</p>
</div>
