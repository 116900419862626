import { IEntity } from "../../sharedModels/shared.interface";

export interface IMaintenance extends IEntity {
  plannedStart: string;
  estimatedEnd: string;
  message?: string;
  maintenanceType: MaintenanceType;
  maintenanceStatus: MaintenanceStatus;
}
export enum MaintenanceType {
  CLASSIC = "classic",
  HOTFIX = "hotfix",
}
export enum MaintenanceStatus {
  PLANNED = "planned",
  CANCELED = "cancelled",
  ONGOING = "ongoing",
  FINISHED = "finished",
}
