<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>
<mat-sidenav-container class="sidenav-container" *ngIf="!loading">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    opened="false"
    id="sideMenu"
  >
    <div class="container">
      <div>
        <div class="userInfos">
          <p class="nameLabel" *ngIf="user && user.identifier">
            {{ user.identifier }}
          </p>
        </div>
        <mat-nav-list *ngIf="user">
          <a mat-list-item (click)="drawer.toggle()" routerLink="/maintenances" routerLinkActive="active" aria-label="maintenance">{{
            "page.maintenances.title" | translate
          }}</a>
        </mat-nav-list>
      </div>
      <mat-nav-list *ngIf="user">
        <a mat-list-item href="" (click)="logout()" id="sideMenuLogout"
          >{{ "menu.logout" | translate }}
          <mat-icon class="fail ml-15">exit_to_app</mat-icon>
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-display-environment-dialog></app-display-environment-dialog>
    <mat-toolbar color="grey" class="mainToolBar" *ngIf="user" id="topMenu">
      <button type="button" id="sidenavOpenBtn" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="spacer"></span>
      <p id="topMenuTitle">{{ pageTitle | translate }}</p>
      <span class="spacer"></span>
      <button
        mat-icon-button
        matTooltip="{{ 'menu.logout' | translate }}"
        class="fail mt-15"
        (click)="logout()"
        aria-label="icon button with a logout icon"
        id="topMenuLogout"
      >
        <a href="">
          <mat-icon class="fail">exit_to_app</mat-icon>
        </a>
      </button>
    </mat-toolbar>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
