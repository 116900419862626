import { FormControl, FormGroup, NgModel } from "@angular/forms";

export abstract class FormPage {
  abstract getForm(): FormGroup;

  protected validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public isFieldValid(field: string): boolean {
    return !this.getForm().get(field).valid && this.getForm().get(field).touched;
  }

  public isFieldIndeterminate(field: string): boolean {
    return this.getForm().get(field).value === undefined;
  }

  public isModelValid(field: NgModel): boolean {
    return field.errors && (field.dirty || field.touched);
  }

  public displayFieldCss(field: string): { "has-error": boolean; "has-feedback": boolean } {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }
}
