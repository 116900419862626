import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/sharedServices/api.service";
import { IMaintenance, MaintenanceStatus, MaintenanceType } from "./maintenance.interface";

@Injectable({
  providedIn: "root",
})
export class MaintenanceService {
  private mainUrl = "maintenance";
  constructor(private api: ApiService) {}

  public get(
    id?: string,
    maintenanceStatus?: MaintenanceStatus[],
    maintenanceTypes?: MaintenanceType[],
    from?: string,
    to?: string,
    limit?: number,
    skip?: number
  ): Observable<IMaintenance | IMaintenance[]> {
    const params = {
      maintenanceStatus: maintenanceStatus ? maintenanceStatus.join(",") : undefined,
      maintenanceTypes: maintenanceTypes ? maintenanceTypes.join(",") : undefined,
      from,
      to,
      limit,
      skip,
    };
    const url = this.mainUrl + (id ? "/" + id : "");
    return this.api.get(url, params, true) as Observable<IMaintenance | IMaintenance[]>;
  }
  public post(plannedStart: string, estimatedEnd: string, maintenanceType?: MaintenanceType, message?: string): Observable<IMaintenance> {
    const body = {
      plannedStart,
      estimatedEnd,
      maintenanceType,
      message,
    };
    return this.api.post(this.mainUrl, body) as Observable<IMaintenance>;
  }
  public put(
    id: string,
    maintenanceStatus: MaintenanceStatus,
    plannedStart?: string,
    estimatedEnd?: string,
    maintenanceType?: MaintenanceType,
    message?: string
  ): Observable<IMaintenance> {
    const body = {
      plannedStart,
      estimatedEnd,
      maintenanceType,
      maintenanceStatus,
      message,
    };
    const url = this.mainUrl + (id ? "/" + id : "");
    return this.api.put(url, body) as Observable<IMaintenance>;
  }
  public count(
    maintenanceStatus?: MaintenanceStatus[],
    maintenanceTypes?: MaintenanceType[],
    from?: string,
    to?: string
  ): Observable<number> {
    const params = {
      maintenanceStatus: maintenanceStatus ? maintenanceStatus.join(",") : undefined,
      maintenanceTypes: maintenanceTypes ? maintenanceTypes.join(",") : undefined,
      from,
      to,
    };
    const url = "count/maintenance";
    return this.api.get(url, params, true) as Observable<number>;
  }
}
