<button mat-stroked-button style="float: right; margin: 20px" color="primary" (click)="createMaintenance()">
  <mat-icon>add</mat-icon>
</button>
<div style="display: flex">
  <mat-form-field class="filter">
    <mat-label>{{ "maintenance.type.title" | translate }}</mat-label>
    <mat-select [(ngModel)]="typeFilter" (selectionChange)="reloadData()" multiple>
      <mat-option *ngFor="let type of maintenanceTypes | keyvalue" [value]="type.value">
        {{ "maintenance.type." + type.value | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="filter">
    <mat-label>{{ "maintenance.status.title" | translate }}</mat-label>
    <mat-select [(ngModel)]="statusFilter" (selectionChange)="reloadData()" multiple>
      <mat-option *ngFor="let status of maintenanceStatus | keyvalue" [value]="status.value">
        {{ "maintenance.status." + status.value | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <form [formGroup]="filterDatesForm">
    <div class="datePickerContainer ml-5">
      <mat-form-field class="datePickerField ml-5">
        <input matInput [matDatepicker]="fromDateTable" formControlName="from" placeholder="{{ 'itemFilter.fromDate' | translate }}" />
        <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
        <mat-datepicker #fromDateTable></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="datePickerField ml-5">
        <input matInput [matDatepicker]="toDate" formControlName="to" placeholder="{{ 'itemFilter.toDate' | translate }}" />
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>
<div>
  <mat-table class="mat-elevation-z1" [dataSource]="datasource">
    <!-- Request id column -->
    <ng-container matColumnDef="maintenance.status.title">
      <mat-header-cell *matHeaderCellDef>
        {{ "maintenance.status.title" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ "maintenance.status." + element.maintenanceStatus | translate }}
      </mat-cell>
    </ng-container>
    <!-- Patient firstname column -->
    <ng-container matColumnDef="maintenance.plannedStart">
      <mat-header-cell *matHeaderCellDef>
        {{ "maintenance.plannedStart" | translate }}
      </mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let element">
        {{ element.plannedStart | date : "dd/MM/yy, HH:mm" }}
      </mat-cell>
    </ng-container>
    <!-- Patient lastname column -->
    <ng-container matColumnDef="maintenance.estimatedEnd">
      <mat-header-cell *matHeaderCellDef>
        {{ "maintenance.estimatedEnd" | translate }}
      </mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let element">
        {{ element.estimatedEnd | date : "dd/MM/yy, HH:mm" }}
      </mat-cell>
    </ng-container>
    <!-- Request date column -->
    <ng-container matColumnDef="maintenance.type.title">
      <mat-header-cell *matHeaderCellDef>
        {{ "maintenance.type.title" | translate }}
      </mat-header-cell>
      <mat-cell class="duration-cell" *matCellDef="let element">
        {{ "maintenance.type." + element.maintenanceType | translate }}
      </mat-cell>
    </ng-container>
    <!-- Request status column -->
    <ng-container matColumnDef="maintenance.message">
      <mat-header-cell *matHeaderCellDef>
        {{ "maintenance.message" | translate }}
      </mat-header-cell>
      <mat-cell class="duration-cell" *matCellDef="let element">
        {{ element.message }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>
        {{ "table.action" | translate }}
      </mat-header-cell>
      <mat-cell class="duration-cell" *matCellDef="let element">
        <button mat-icon-button (click)="updateMaintenance(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator class="mat-elevation-z1" [length]="count" [pageSize]="limit" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
