import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class MatPaginatorI18N extends MatPaginatorIntl implements OnDestroy {
  public itemsPerPageLabel: string;
  public nextPageLabel: string;
  public previousPageLabel: string;
  public ofLabel: string;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.getAndInitTranslations());
    this.getAndInitTranslations();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getAndInitTranslations(): void {
    this.translate
      .get(["paginator.itemsPerPage", "paginator.nextPage", "paginator.previousPage", "paginator.ofLabel"])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation["paginator.itemsPerPage"];
        this.nextPageLabel = translation["paginator.nextPage"];
        this.previousPageLabel = translation["paginator.previousPage"];
        this.ofLabel = translation["paginator.ofLabel"];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };
}
