import { ErrorHandler, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SessionService } from "../sharedServices/session.service";
import { AppError } from "./app-error.interface";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public catchedError = new Subject<AppError>();

  constructor(private sessionService: SessionService) {}

  public handleError(error: AppError): void {
    if (error.code === 403 || error.code === 0) {
      this.sessionService.clear();
      window.location.href = "/";
    }

    this.catchedError.next(error);
  }
}
