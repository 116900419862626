import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { IUser } from "../data/users/user.interface";

export const enum PATH_URL {
  PRODUCTION = ".appserver.comunicare.io",
  QA = ".testsrv.comunicare.io",
  DEV = ".devsrv.comunicare.io",
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private readonly userSubject = new BehaviorSubject<IUser>(undefined);
  private readonly COOKIE_ACCESS_TOKEN = "x-access-token";
  private readonly tokenIndex = "access_token";
  private readonly langIndex = "lang";
  public readonly DEFAULT_LANG = "fr";

  constructor(private cookieService: CookieService) {}
  public set token(token: string) {
    localStorage.setItem(this.tokenIndex, token);
  }

  public get token(): string {
    if (this.cookieService.check(this.COOKIE_ACCESS_TOKEN)) {
      const cookieToken = this.cookieService.get(this.COOKIE_ACCESS_TOKEN);
      this.cookieService.delete(this.COOKIE_ACCESS_TOKEN, "/", this.pathByEnv);
      this.token = cookieToken;
    }
    return localStorage.getItem(this.tokenIndex);
  }
  public get pathByEnv(): string {
    return environment.production ? PATH_URL.PRODUCTION : environment.envName === "dev" ? PATH_URL.DEV : PATH_URL.QA;
  }
  public set userLang(lang: string) {
    localStorage.setItem(this.langIndex, lang);
  }

  public get userLang(): string {
    const lang = localStorage.getItem(this.langIndex);
    return lang ? lang : this.DEFAULT_LANG;
  }
  public set user(account: IUser) {
    this.userSubject.next(account);
  }

  public get user(): IUser {
    return this.userSubject.getValue();
  }

  public watchUser(): BehaviorSubject<IUser> {
    return this.userSubject;
  }

  public clear(): void {
    this.user = undefined;
    localStorage.removeItem(this.tokenIndex);
    if (this.cookieService.check(this.COOKIE_ACCESS_TOKEN)) {
      this.cookieService.delete(this.COOKIE_ACCESS_TOKEN, "/", this.pathByEnv);
    }
  }
}
